@import "npm:material-icons/iconfont/material-icons.css";
@import "npm:@fontsource/oswald/400.css";
@import "npm:@materializecss/materialize/sass/materialize.scss";


@import "_color.scss";
/* Custom Stylesheet */
/**
 * Use this file to override Materialize files so you can update
 * the core Materialize files in the future
 *
 * Made By MaterializeCSS.com
 */

$bgcolor_main: color("nachtblau", "darken-3");
$bgcolor_accent: color("nachtblau", "darken-4");
$bgcolor_menu: color("nachtblau", "darken-2");
$bgcolor_footer: desaturate(color("nachtblau", "lighten-1"), 50%);//desaturate(lighten($bgcolor_main, 0%), 85%);

html {
  //font-family: 'Alegreya Sans', sans-serif;
  font-family: Oswald, sans-serif;
  font-weight: 300;
}
body {
  max-width: 1900px;
  margin: 0 auto;
  background-color: $bgcolor_main;
  color: white;
}
strong {
  font-weight: 400;
}
h1,
h2,
h3,
h4,
h5 {
  font-weight: 700;
}
#materialbox-overlay {
  background-color: $bgcolor-main;
}
nav {
  &.nav-fixed {
    position: fixed;
    right: 0;
    left: 0;
    top: 0;
    margin-bottom: 0;
    z-index: 997;
  }
  background-color: $bgcolor_menu;
  color: white;//color("grey", "darken-3");
  // border-top: 15px solid color("indigo","darken-4");
  border-top: 0 none;
  box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.16), 0 5px 10px -5px rgba(0, 0, 0, 0.12); // nur unten
  height: 64px;
  transition: all 0.4s ease;
  max-width: 1900px;
  img.logo,
  svg.logo {
    height: 42px;
    width: auto;
    vertical-align: top;
    transition: all 0.4s ease;
    padding-top: 0;
    margin-top: 10px;
    path.fil0 {
      fill: color("grey", "darken-3");
    }
  }
  a.brand-logo.right {
    transition: all 0.4s ease;
    padding-top: 0;
  }
  ul {
    &.left {
      padding-top: 0;
      transition: all 0.4s ease;
    }
    a {
      font-size: 1rem;
      font-weight: 300;
      transition: all 0.4s ease;
      color: white;//color("grey", "darken-3");
    }
    li {
      padding: 0 10px;
      &.small a{
        font-size: 80%;
      }
      &.small .material-icons {
        transform: scale(0.5);
        color: white;// color("grey", "lighten-1");
      }
      a {
        padding: 0 5px;
        display: inline;
        &:hover,
        &.active {
          background: inherit;
          border-bottom: 3px solid white; //color("indigo", "darken-4");
        }
      }
    }
  }
}
#home, #contact {
  background-color: $bgcolor_accent;
}
p {
  line-height: 2rem;
}
.button-collapse {
  color: #26a69a;
}
#gmap {
  height: 500px;
}
.gm-style,
.gm-style div {
  font-family: inherit !important;
}
h1,
h4,
h5 {
  color: white;//color("indigo", "darken-4");
}
.flow-text {
  line-height: 150%;
}
$section-padding: 3rem;
.section {
  padding-top: $section-padding;
  padding-bottom: $section-padding;
}
#competences .material-icons {
  color: color("cntx-red", "base");
}
.contact {
  .details {
      .material-icons {
        line-height: inherit;
      }
    }
  }
.icon-block {
  padding: 0 15px;
  .material-icons {
    font-size: inherit;
  }
}
footer.page-footer {
  margin-top: 0;
  font-size: 90%;
  background-color: $bgcolor_footer;
  color: color("grey", "lighten-2");
  .disclaimer {
    color: color("grey", "lighten-1");
    h5,
    p {
      font-size: 95%;
      color: inherit;
    }
    h5 {
      font-weight: 400;
      margin-top: 21px;
    }
  }
  div.remarks {
    color: color("grey", "lighten-2");
    //background-color: color("grey", "lighten-3");
    div.esf {
      transition: all 1s ease;
      overflow: hidden;
      max-height: 500px;
      &.zero {
        max-height: 0;
      }
      h5 {
        color: inherit;
      }
    }
  }
  img {
    vertical-align: middle;
    margin-top: 20px;
    &.esf {
      height: 46px;
      margin-bottom: -20px;
      &:hover {
        cursor: pointer;
      }
    }
    &.oes {
      height: 69px;
      margin-left: 40px;
    }
  }
  .footer-copyright {
    background-color: $bgcolor_menu;
    height: 48px;
    line-height: 48px;
    img.esf {
      background-color: white;
      border: 5px solid white;
      border-radius: 2px;
    }
  }
  p {
    line-height: 135%;
  }
}
.no-mar-bot {
  margin-bottom: 0;
}
.info_content {
  h5 {
    margin-bottom: 0;
  }
  a {
    color: inherit !important;
  }
  .row {
    margin-bottom: 0;
    p {
      white-space: nowrap;
      font-size: 85%;
      margin-top: 0;
      margin-bottom: 0;
    }
  }
}
$play_size: 240px;

.video_wrapper {
  height: auto;
  width: 100%;
  background-size: contain;
  background-repeat: no-repeat;
  aspect-ratio: 16 / 9;
  .video_trigger {
    &:before {
      content: "play_arrow";
      font-family: 'Material Icons';
      width: $play_size;
      aspect-ratio: 1;
      font-size: $play_size;
      line-height: $play_size;
      text-align: center;
      color: transparentize(white,0.5);
      position: absolute;
      z-index: -1;
      top: calc(50% - $play_size / 2);
      left: calc(50% - $play_size / 2);
    }

    padding: 100px 100px;
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 9;
    background-color: transparentize(color("nachtblau", "base"),0.5);
    color: white;

    a {
      color: color("yellow", "base");
    }
  }
  .video_layer {
    position: relative;
    display: none;
    height: 100%;
    width: 100%;
    margin: auto;
    top: 0;
    left: 0;

    iframe {
      border: 0px none transparent;
      height: 100%;
      width: 100%;
      position: relative;
    }
  }
}

/* Besonderheiten nach Bildschirmauflösung */
/* large an up */
@media only screen and (min-width: 993px) {
  nav.grow {
    height: 109px;
    img.logo {
      height: 72px;
      padding-top: 0;
      vertical-align: middle;
    }
    a.brand-logo.right {
      /*padding-top: 10px;*/
    }
    ul {
      a {
        font-size: 1.5rem;
      }
      &.left {
        padding-top: 20px;
      }
    }
  }
  .bg1 {
    margin-top: 15px;
  }
}
/* 1600px and down */
@media only screen and (max-width: 1600px) {
  #contact .parallax img {
    min-width: 1600px;
    max-width: 1600px;
  }
}
/* 1200px and down */
@media only screen and (max-width: 1200px) {
  #contact {
    .parallax-container {
      min-height: 500px;
      .parallax img {
        min-width: 1200px;
        max-width: 1200px;
      }
    }
  }
}
/* med and up */
@media only screen and (min-width: 601px) {
  .hide-on-med-and-up {
    display: none !important;
  }
}
/* med and down */
@media only screen and (max-width : 992px) {
  .parallax img {
    min-height: initial;
    max-width: 1400px;
  }
  .parallax-container .section {
    position: absolute;
    // top: 10%;
    h1 {
      font-size: 3.5rem;
    }
    h5 {
      font-size: 1.5rem;
    }
  }
  #index-banner .section {
    top: 10%;
  }
  .bg1 {
    // margin-top: 15px;
  }
}
/* small and down */
@media only screen and (max-width : 600px) {
  #index-banner .section {
    top: 0;
  }
  .footer-copyright {
    height: auto !important;
  }
}
/* very small */
@media only screen and (max-width: 384px) {
  footer {
    img {
      &.oes {
        margin-top: 40px;
      }
    }
  }
}
