// Utility Color Classes
// Code fon materializecss übernommen und für CNTX Farben angepasst

//.success {
//
//}

@use "sass:map";

$nachtblau: (
  "lighten-5":  #e4e6eb,
  "lighten-4":  #bdc0ce,
  "lighten-3":  #9196ad,
  "lighten-2":  #646c8c,
  "lighten-1":  #434d73,
  "base":       #222d5a,
  "darken-1":   #1e2852,
  "darken-2":   #192248,
  "darken-3":   #141c3f,
  "darken-4":   #0c112e,
  "accent-1":   #6a7bff,
  "accent-2":   #374dff,
  "accent-3":   #0420ff,
  "accent-4":   #001aea
);


$cntx-red: (
  "lighten-5":  #fce5e7,
  "lighten-4":  #f5a0a8,
  "lighten-3":  #ef6d79,
  "lighten-2":  #e82d3e,
  "lighten-1":  #df182a,
  "base":       #c31525,
  "darken-1":   #a71220,
  "darken-2":   #8c0f1b,
  "darken-3":   #700c15,
  "darken-4":   #540910,
  "accent-1":   #fce5e7,
  "accent-2":   #f5a0a8,
  "accent-3":   #df182a,
  "accent-4":   #8c0f1b
);

$mycolors: (
  "cntx-red": $cntx-red, "nachtblau": $nachtblau
);

$colors: map.merge($colors, $mycolors) !global;

// Color Classes

@each $color_name, $color in $colors {
  @each $color_type, $color_value in $color {
    @if $color_type == "base" {
      .#{$color_name} {
        background-color: $color_value !important;
      }
      .#{$color_name}-text {
        color: $color_value !important;
      }
    }
    @else if $color_name != "shades" {
      .#{$color_name}.#{$color_type} {
        background-color: $color_value !important;
      }
      .#{$color_name}-text.text-#{$color_type} {
        color: $color_value !important;
      }
    }
  }
}



